<template>
  <v-container fluid :class="['pa-0', containerConditionalClasses]">
    <v-stepper v-model="step" class="elevation-0 osg-height-100">
      <v-stepper-items ref="steperContainer" :class="['overflow-y-auto', stepperConditionalClasses]">
        <v-container>
          <v-row no-gutters v-if="alert">
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="mx-auto my-0">
              <v-alert
                v-model="alert"
                dismissible
                dense
                border="left"
                elevation="2"
                type="error"
                class="my-0"
                colored-border
              >{{ getError.error }}</v-alert>
            </v-col>
          </v-row>              
          <v-stepper-content
            class="px-0 pt-0"
            v-for="n in formSteps"
            :key="`${n}-content`"
            :step="n"
          >
            <v-row class="px-3">
              <v-container v-if="!getFormFields(n) || !getFormFields(n).grouped">
                <v-row>
                  <AuthorizationField 
                    :ref="field.slug"
                    v-for="field in getFormFields(n) ? getFormFields(n).fields : backToHome()" 
                    :key="field.slug"
                    @input="changeInput($event, field)" 
                    :field="field"/>
                </v-row>
              </v-container>
              <v-container v-if="getFormFields(n) && getFormFields(n).grouped">
                <v-row  v-for="groupField in getFormFields(n).fields" :key="groupField.group">
                  <v-col cols="12">
                    <span class="text-h5 secondary--text">{{ groupField.group }}</span>
                  </v-col>
                  <AuthorizationField 
                    :ref="field.slug"
                    v-for="field in groupField.fields" 
                    :key="field.slug"
                    @input="changeInput($event, field)" 
                    :field="field"/>
                </v-row>
              </v-container>
            </v-row>
          </v-stepper-content>       
        </v-container>
      </v-stepper-items>       
      <v-stepper-header class="elevation-0 grey lighten-4" >
        <v-row no-gutters class="mx-auto">
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? 9 : 12" class="grey lighten-4">
            <v-row align="center" v-if="authorizationForms.length > 1 && step == 1">
              <v-col class="d-flex align-center mt-2">
                <span class="px-6 secondary--text">{{ $t('addAuthorization.selectPreAuthorizationMessage') }}</span>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="showSteps()" class="pl-3">
              <v-col v-for="n in formSteps" :key="n" :cols="$vuetify.breakpoint.mdAndUp ? 0 : 12 ">
                <template>
                  <v-stepper-step
                    v-if="showStep(n)"
                    :key="`${n}-step`"
                    class="form-step"
                    :complete="step > n"
                    :step="n"
                    color="secondary"
                    @click="goBackStep(n)"
                    :rules="[() => hasError(n)]"
                  >
                    <span 
                      :class="['px-2 secondary--text', $vuetify.breakpoint.mdAndUp ? 'osg-step-label' : 'osg-step-label-mobile' ]"
                    >
                      {{ getFormFields(n).fields[0].groupName }}
                    </span>                  
                  </v-stepper-step>
                </template>
              </v-col>              
            </v-row>
          </v-col>
          <v-col 
            :cols="$vuetify.breakpoint.mdAndUp ? 3 : 12" 
            :class="['d-flex grey lighten-4', $vuetify.breakpoint.mdAndUp ? 'justify-end pr-7 align-center' : 'justify-space-around osg-height-100']"   
          >
            <v-btn
              depressed
              rounded
              color="white"
              min-width="102"
              :class="[$vuetify.breakpoint.mdAndUp ? 'mr-5' : 'mr-16']"
              @click="onBackStep"
            >
              {{ $t(backButton) }}
            </v-btn>
            <v-btn
              depressed
              color="primary"
              min-width="102"
              rounded
              @click="onNextStep"
            >
              {{ $t(nextButton) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-header>      
    </v-stepper>  
  </v-container>
</template>

<script>
import AuthorizationField from './AuthorizationField'
import { createNamespacedHelpers } from 'vuex'

const authorizationModule = createNamespacedHelpers('authorization');

export default {
  name: 'AddAuthorizationContainer',
  components: {AuthorizationField},
  mounted() {
    this.validateState()
    this.setCurrentStep(1);
    this.getSponsorConfiguration();
  },
  methods: {
    ...authorizationModule.mapActions(['getAuthorizationsForm', 'getSponsorConfiguration', 'setCurrentStep',
    'setStepError', 'setInput', 'setError', 'setIsReimbursement', 'nextStep', 'lastStep']),

    validateState() {
      if (!this.authorizationForms || this.authorizationForms.length === 0) {
        this.backToHome();
      }
    },

    showSteps() {
      if(this.authorizationForms.length ==1){
        return true
      } else if(this.currentStep > 1) {
        return true
      }
      return false;
    },

    validate() {
      let valid = true;
      const fields = this.$refs;
      if (fields) {
        const groupFields = this.getFormFields(this.step);
        if (!groupFields.grouped && groupFields.fields) {          
          groupFields.fields.forEach(formField => {
            const validation = fields[formField.slug][0].validate();
            if (valid) {
              valid = validation;
            }          
          })          
        } else {
          if(groupFields.fields){
            groupFields.fields.forEach(groupField => {
              groupField.fields.forEach(formField => {
                const validation = fields[formField.slug][0].validate();
                if (valid) {
                  valid = validation;
                }          
              })
            })
          }
        }        
      }

      this.setStepError(valid);
      return valid;
    },
    getFormFields(step) {
      let currentForm = this.currentForm;
      let groupOfFields = [];
      if (currentForm || this.authorizationForms.length > 0) {
        if (!currentForm) {
          currentForm = this.authorizationForms[0].formSlug;
        }
        if(!this.currentForm) {
          groupOfFields = this.authorizationFormsAndSteps[currentForm][0];
        } else {
          groupOfFields = this.authorizationFormsAndSteps[currentForm][step - 1];
        }
      }

      if(!groupOfFields) {
        this.backToHome();
      }

      const fields = this.getFieldsRestrictions(groupOfFields.fields);

      if(fields && fields.length > 0) {
        return {...groupOfFields, fields};
      }

      this.backToHome();
    },
    changeInput(event, field) {
      this.setInput({ slug: field.slug, value: event });
    },
    onNextStep() {
      const valid = this.validate();
      if (valid) {
        const container = this.$refs.steperContainer;
        container.scrollTo(0, 0);
        this.nextStep(this.formSteps);
      }
    }, 
    goBackStep(currentStepper) {
      if (currentStepper < this.step) {
        this.setCurrentStep(currentStepper);
        this.setStepError(true);
        this.setError({hasError: false, error: null})
      }
    },
    onBackStep() {
      this.lastStep();
    },
    hasError(step) {
      return this.step === step ? this.stepError : true
    },
    showStep(n) {
      if(this.$vuetify.breakpoint.mdAndUp) {
        return true;
      } else {
        return n === this.currentStep
      }
    },
    getFieldsRestrictions(fields) {
      if (this.currentForm === 'mx-findep-ppi' || this.currentForm === 'mx-aef-ppi') {
        const inputInsuranceClaimType = this.inputs.find(input => input.slug === 'insurance_claim_type')
        if (inputInsuranceClaimType && inputInsuranceClaimType.value !== 'HOSPITAL_DAILY_RENT') {
          fields = fields.filter(fields => fields.slug !== 'days_of_hospitalization_stay' && fields.slug !== 'amount_for_hospitalization')
          const amountCoveredIndex = fields.findIndex(field => field.slug === 'amount_covered')
          if (amountCoveredIndex >= 0) {
            fields[amountCoveredIndex] = {...fields[amountCoveredIndex], min: null, max: null, defaultValue: 20000 }
          }
        } else {
          const amountCoveredIndex = fields.findIndex(field => field.slug === 'amount_covered')
          if (amountCoveredIndex >= 0) {
            fields[amountCoveredIndex] = {...fields[amountCoveredIndex], min: 300, max: 9000, defaultValue: null }
          }
        }
      } 
      return fields
    },
    backToHome(){
      this.$router.push('/home').catch(() => {})
    }
  },
  computed: {
    ...authorizationModule.mapGetters(
      ['authorizationForms', 'formSteps', 'currentStep', 'currentForm', 'authorizationFormsAndSteps',
      'stepError', 'getError', 'inputs', 'sponsorConfigurations']),
    containerConditionalClasses() {
      return {
        'osg-container-mobile-height-link': this.isMobile && this.hasExternalDocumentationLink,
        'osg-container-mobile-height': this.isMobile && !this.hasExternalDocumentationLink,
        'osg-container-desktop-height': !this.isMobile
      }
    },
    stepperConditionalClasses() {
      return {
        'osg-stepper-mobile-height-link': this.isMobile && this.hasExternalDocumentationLink,
        'osg-stepper-mobile-height': this.isMobile && !this.hasExternalDocumentationLink,
        'osg-stepper-desktop-height': !this.isMobile
      }
    },  
    hasExternalDocumentationLink() {
      return this.sponsorConfigurations.externalDocumentationLink;
    },      
    alert: {
      get() {
        return this.getError.hasError;
      },
      set(value) {
        this.setError({hasError: value, error: null})
      }
    },
    nextButton() {
      const text = this.currentStep === this.formSteps ? 
        'addAuthorization.buttons.end' : 'addAuthorization.buttons.next';
      return text;
    },
    backButton() {
      const text = this.currentStep === 1 ? 
        'addAuthorization.buttons.cancel' : 'addAuthorization.buttons.return';
      return text;
    },
    step() {
      return this.currentStep;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>

<style scoped>
.v-stepper__header {
  box-shadow: 0px 0px 0px 0px !important;
  border-bottom: 1px solid #e0e0e0;
}
.v-stepper__step {
    padding: 24px 12px !important;
}

.v-stepper__label {
  display: block !important;
}

.osg-container-desktop-height {
  height: calc(100vh - 183px)
}

.osg-container-mobile-height {
  height: calc(100vh - 170px)
}

.osg-stepper-desktop-height {
  height: calc(100vh - 255px)
}

.osg-stepper-mobile-height {
  height: calc(100vh - 300px)
}

.osg-container-mobile-height-link {
  height: calc(100vh - 226px)
}

.osg-stepper-mobile-height-link {
  height: calc(100vh - 356px)
}

.osg-height-100 {
  height: 100%;
}

.osg-step-label {
  font-size: 0.688rem;
  line-height: normal;
  letter-spacing: 0.25px !important;
  font-family: "Open Sans" !important;
}
.osg-step-label-mobile{
  font-size: 0.813rem;
  letter-spacing: normal;
  font-family: "Open Sans" !important;
}

@media only screen and (max-width: 959px) {
  ::v-deep .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: block !important;
  }

}

.osg-display-none {
  display: none;
}

</style>